/*These css classes are needed before the MUI Theme is loaded. All other style properties are written in JSX*/
.loadingCenterVerticalHorizontal {
    width: 60px;
    height: 60px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}
.loadingCenterVerticalHorizontalBackground {
    background-color: rgba(255, 255, 255, 0.81);
    height: 100%;
    width: 100%;
    position: fixed;
}
